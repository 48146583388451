<template>
  <VAlert
    :value="isOpen"
    color="treps-blue"
    :class="[
      'global-notice my-0 pa-4',
      { 'sm-and-down': ['xs', 'sm'].includes($mq) },
    ]"
    transition="fade-slow"
    mode="in-out"
  >
    <VLayout row justify-center>
      <VFlex xs10 sm8 lg6 class="white--text">
        <BaseSiteContent
          tag="h2"
          tag-class="header-4"
          content-key="covid_alert_title"
          default-content="Oyster Bar Now Open"
        />
        <BaseSiteContent
          tag="p"
          tag-class="r-headline"
          content-key="covid_alert_text"
          default-content="We're Serving Fresh Gulf Oysters every weekend.<br/>Fri, Sat &amp; Sun 11am-10pm"
        />
        <BaseButtonOutline
          class="ml-0"
          small
          outline-color="white"
          @click="toggleGlobalNotice"
          >Close</BaseButtonOutline
        >
      </VFlex>
    </VLayout>
  </VAlert>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TrepsGlobalNotice',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('nav', ['getGlobalNoticeState']),
    isOpen: {
      get() {
        return this.getGlobalNoticeState
      },
      set() {
        this.toggleGlobalNotice()
      },
    }, // isOpen
  }, // computed
  methods: {
    ...mapActions('nav', ['toggleGlobalNotice']),
  },
}
</script>

<style lang="scss" scoped>
.global-notice {
  height: auto;
  min-height: 20vh;

  &.sm-and-down {
    padding-top: 120px;
    padding-bottom: 60px;
  }

  ::v-deep p {
    font-size: 20px;
    letter-spacing: 0.012em;
  }
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1s;
}
.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}
.fade-slow-enter-active {
  transition-delay: 1s;
}
</style>
